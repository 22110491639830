import React from 'react'
import LogoNameStack from '../LogoNameStack/LogoNameStack'
import styles from './BigProjectCard.module.css'
import {ThemeContext} from '../ThemeContext/ThemeContext'
import { Link } from "gatsby"

export default function BigProjectCard({id, name, description, buttons}) {
    const { colorMode } = React.useContext(ThemeContext);

    return (
        <div id = {id} className = {styles.main}>
            <LogoNameStack name = {name} id = {id}/>
            <div className = {styles.description}>
                <p style = {{opacity: 0}}>
                    {description.map((stringItem, index) => {
                        if (stringItem.slug) {
                            return <Link key = {index} to = {stringItem.slug}>
                                    <span style = {{fontWeight: 700, color: stringItem.color, textDecoration: 'underline'}}>{stringItem.value}</span>
                                </Link>
                        }
                        if (stringItem.url) {
                            return <a key = {index} href = {stringItem.url} target="_blank" rel="noopener noreferrer"><span style = {{fontWeight: 700, color: stringItem.color, textDecoration: 'underline'}}>{stringItem.value}</span></a>
                        }
                        if (stringItem.bold === true) {
                            return <span key = {index} style = {{fontWeight: 700}}>{stringItem.value}</span>
                        } else {
                            return <span key = {index} >{stringItem.value}</span>
                        }
                    })}
                </p>

                <div className = {styles.logoTitle}>
                    {
                        buttons.map((button, index) => {
                            return (
                            <a key = {index} style = {{opacity: 0}} href = {button.url} target = '_blank' rel="noopener noreferrer">
                                <img className = {styles.logo} src = {require('./images/' + button.platform + '.png')} alt = "Big Project Card Logo"/>
                            </a>)
                        })
                    }
                </div>
            </div>

            <div className = {styles.screenshots} >
                <img style = {{opacity: 0}} src = {require('./images/' + id + '-1-' + (colorMode === 'light' ? 'light' : 'dark') + '.svg')} alt = "Big Project Card Screenshot 1"></img>
                <img style = {{opacity: 0}} src = {require('./images/' + id + '-2-' + (colorMode === 'light' ? 'light' : 'dark') + '.svg')} alt = "Big Project Card Screenshot 2"></img>
                <img style = {{opacity: 0}} src = {require('./images/' + id + '-3-' + (colorMode === 'light' ? 'light' : 'dark') + '.svg')} alt = "Big Project Card Screenshot 3"></img>
            </div>
        </div>
    )
}
