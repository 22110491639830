import React from 'react'
import ImageTextButton from '../ImageTextButton/ImageTextButton'
import styles from './SmallProjectCard.module.css'
import {ThemeContext} from '../ThemeContext/ThemeContext'
export default function SmallProjectCard({id, name, description, link, primaryLanguageName}) {
    const { colorMode } = React.useContext(ThemeContext);
    return (
        <>
            <div id = {id} className = {styles.main}>
                
                <div className = {styles.imageNameFlex}>
                    <div id = {`${id}-card`}className = {styles.image}>

                            <img style = {{width: '100%', height: '100%'}}src = {require('../../images/icons/' + id + ".jpg")} alt = "Small Project Card Logo"></img>
                    </div>
                    <p className = {styles.name}>{name}</p>
                </div>

                <p className = {styles.description}>
                    {description.map(stringItem => {
                        if (stringItem.bold === true) {
                            return <span key = {stringItem.value} style = {{fontWeight: 700}}>{stringItem.value}</span>
                        } else {
                            return <span key = {stringItem.value} >{stringItem.value}</span>
                        }
                    })}
                </p>

                <ImageTextButton name = "Github" imageName = "Github" link = {link} color = '#613467'/>

                <div className = {styles.language}>
                    <object type="image/svg+xml" data={require('./images/' + primaryLanguageName + "-" + (colorMode === 'light' ? 'light' : 'dark') + '.svg')}>Your browser does
                    not support SVGs</object>                        
                </div>
            </div>
        </>
    )
}
