import React from 'react'
import styles from './ExperienceItem.module.css'
import { ThemeContext } from '../ThemeContext/ThemeContext'
export default function ExperienceItem({ id, name, jobTitle, color, dateRange, description }) {
    const { colorMode } = React.useContext(ThemeContext);

    return (
        <div className={styles.main}>
            <div className={styles.timeline}>
                <div className={styles.timelineCircle + " rounded-card"}></div>
                <div className={styles.timelineRect + " rounded-card"}></div>
            </div>

            <div className={styles.rightSide}>

                <div className={styles.content}>
                    <div className={styles.contentLogoName}>
                        <div className={styles.contentLogoNameImage + ' rounded-card'}>
                            <img src={require('./images/' + id + '.svg')} alt="Company Logo"></img>
                        </div>
                        <div className={styles.contentLogoNameNameJob}>
                            <div className={styles.contentLogoNameName} style={{ color: color }}>
                                <h2>{name}</h2>
                            </div>
                            <div className={styles.contentLogoNameJob}>
                                <p>{jobTitle}</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.contentDate}>
                        <p>{dateRange}</p>
                    </div>
                    <div className={styles.contentDescription}>
                        <p>{description}</p>
                    </div>
                </div>
                <div className={styles.showcaseImage}>
                    <img src={require('./images/' + id + '-Info-' + (colorMode === 'light' ? 'light' : 'dark') + '.svg')} alt="Company Product"></img>
                </div>

            </div>
        </div>
    )
}
