import React from 'react'
import styles from './LargeImageTextButton.module.css'
export default function LargeImageTextButton({name, imageName, link}) {
    return (
        <a href={link} target="_blank" rel="noopener noreferrer">
            <div className = {styles.main}>
                <img src = {require('./images/' + imageName + ".svg")} className = {styles.image} alt = "Button Logo"></img>
                <p className = {styles.name}>{name}</p>
            </div>
        </a>
    )
}
