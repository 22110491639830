const experiences = [
  {
    id: "Robinhood",
    name: "Robinhood",
    jobTitle: "iOS Developer",
    color: "#53C38E",
    dateRange: "May 2021 - August 2021",
    description: `Robinhood provides customers with zero comission trading on their iOS, Android and Web platforms with no cost to the user and the mission to democratize finance for all. 
    
    I was a member of the News and Education team working on projects to inform users on how different features in the Robinhood app work. I implemented a new type of reusable disclosure which is first being used in the Newsfeed.`
  },
  {
    id: "Nanoleaf",
    name: "Nanoleaf",
    jobTitle: "iOS Developer",
    color: "#5BB343",
    dateRange: "May 2020 - December 2020",
    description: `Nanoleaf creates smart home RGB lighting panels which are completely customizable with colour, effects and schedules. All the products work directly with HomeKit, Siri and the Nanoleaf app.
                    
                    I worked on many different aspects of the app, including managing data in Core Data, creating new features with MVVM, UIKit, ARKit and working with multiple networking protocols.`
  },
  {
    id: "UBCLaunchPad",
    name: "UBC Launch Pad",
    jobTitle: "Software Developer",
    color: "#809CFF",
    dateRange: "January 2019 - Present",
    description: `UBC Launch Pad is a student-run software engineering design team. Members work in sub-teams consisting of developers, designers and strategy members to build out a product based on an interesting idea using industry standards and tools.
  
                    I helped to develop an iOS app using Swift and Xcode as a front-end and SQL as a back-end to reduce food waste in everyday life by tracking expiry dates and offering recipes and reminders.`
  },
  {
    id: "ByteCamp",
    name: "Byte Camp",
    jobTitle: "Lead Instructor",
    color: "#4DB546",
    dateRange: "June 2019 - August 2019",
    description: `Byte Camp is a week long summer camp exposing kids to animation and software development tools that they use to complete a project. The camp is meant to teach children fundamental programming and logic skills, while having a great time!
  
                    I taught lessons on 3D Animation, 3D Games, 2D Games and stop-motion using tools such as Blender, Godot and Scratch while also being responsible for the safety of campers.`
  }
]

const bigProjects = [
  {
    id: "CloudBattery",
    name: [
      {
        value: "Cloud"
      },
      {
        value: " Battery",
        color: '#4CB271'
      }
    ],
    description: [
      {
        value: "Cloud Battery ",
        bold: true
      },
      {
        value: "is an app that tracks your battery level on all your Apple devices. This includes watchOS, iOS, iPadOS, macOS and Apple Pencil devices. You can set battery notifications.  "
      },
      {
        value: " Cloud Battery has been a featured app on the Mac App Store, and has been App Of The Day on the iOS one with over 200K users. "
      },
      {
        value: "Learn More",
        bold: true,
        color: '#4CB271',
        slug: '/CloudBattery/'
      }
    ],
    buttons: [
      {
        platform: "iOS",
        url: "https://apps.apple.com/app/id1480648069"
      },
      {
        platform: "macOS",
        url: "https://apps.apple.com/app/id1481005137"
      }
    ]
  },
  {
    id: "FloatingFile",
    name: [
      {
        value: "floating"
      },
      {
        value: "file",
        color: '#435EE9'
      }
    ],
    description: [
      {
        value: "floatingfile ",
        bold: true
      },
      {
        value: "is a cross-platform file sharing application. Users create temporary "
      },
      {
        value: "Spaces ",
        bold: true
      },
      {
        value: "where files can be added. ",
      },
      {
        value: "Spaces ",
        bold: true
      },
      {
        value: "can be joined by other  users and files in the Space can be downloaded.  "
      },
      {
        value: "Learn More",
        bold: true,
        color: '#435EE9',
        url: 'https://www.floatingfile.space'
      }
    ],
    buttons: [
      {
        platform: "iOS",
        url: "https://apps.apple.com/app/id1507080982"
      }
    ]
  },
  {
    id: "Unify",
    name: [
      {
        value: "Unify"
      }
    ],
    description: [
      {
        value: "Unify ",
        bold: true
      },
      {
        value: "is a UBC social media application where students can find other students with classes and interests they share.  Created completely using "
      },
      {
        value: "SwiftUI. ",
        bold: true
      },
      {
        value: "Learn More",
        bold: true,
        color: '#90CDFF',
        slug: '/Unify/'
      }
    ],
    buttons: [
      {
        platform: "iOS",
        url: "https://apps.apple.com/us/app/id1528128389"
      }
    ]
  },
  {
    id: "SocialDrop",
    name: [
      {
        value: "social"
      },
      {
        value: "Drop",
        color: '#E2BA43'
      }
    ],
    description: [
      {
        value: "socialDrop ",
        bold: true
      },
      {
        value: "allows you to share social media accounts with nearby people over "
      },
      {
        value: "MultipeerConnectivity. ",
        bold: true
      },
      {
        value: "When you network in person, 2 taps can drop all your social media accounts to your new connection. "
      },
    ],
    buttons: [
      {
        platform: "iOS",
        url: "https://apps.apple.com/us/app/id1497486296"
      }
    ]
  }
]

const smallProjects = [
  {
    id: "WordSearch",
    name: "Word Search",
    description: [
      {
        value: `
                            The classic crossword puzzle on iOS, allowing for entry of custom words (emojis included) 
                            and generation of a crossword puzzle with a playful UI.
                        `
      },
    ],
    link: "https://github.com/yan-alan/iOS-WordSearch",
    primaryLanguageName: "Swift"
  },
  {
    id: "UIKitToolbox",
    name: 'UIKit Toolbox',
    description: [
      {
        value: `
                  An iOS framework that helps simplify common tasks in UIKit programming and setting up UIViews.
                 `
      }
    ],
    link: "https://github.com/yan-alan/UIKit-Toolbox",
    primaryLanguageName: "Swift"
  },
  {
    id: "Sprouts",
    name: "Sprouts",
    description: [
      {
        value: `
            A Plant Water Management App that helps remind users to water their plants.
            Created at
          `
      },
      {
        value: "nwHacks 2020",
        bold: true
      }
    ],
    link: "https://github.com/yan-alan/nwHacks2020-Sprouts",
    primaryLanguageName: "Swift"
  },
  {
    id: "Mentr",
    name: "Mentr",
    description: [
      {
        value: `
            An app that pairs high schoolers with university student 
            mentors to ease the transition into university.
          `
      }
    ],
    link: "https://github.com/DConnorFong/mentr",
    primaryLanguageName: "Swift"
  },
  {
    id: "DontLoseMe",
    name: "Don't Lose Me",
    description: [
      {
        value: `
            Finding your airpods in your own home is no longer an issue! 
            Don’t Lose Me shows bluetooth devices around and how far they are!
          `
      }
    ],
    link: "https://github.com/yan-alan/Dont-Lose-Me",
    primaryLanguageName: "Swift"
  },
  {
    id: "KeepFresh",
    name: "keepfresh",
    description: [
      {
        value: `
          Allows users to track the food in their homes and find recipes 
          based on what they have. Created at
          `
      },
      {
        value: " UBC Launchpad.",
        bold: true
      }
    ],
    link: "https://github.com/ubclaunchpad/food-doods",
    primaryLanguageName: "Swift"
  }
]

export { bigProjects, smallProjects, experiences };