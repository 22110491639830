import React from 'react'
import styles from './SectionTitle.module.css'
export default function SectionTitle({name, isSelected}) {
    if(isSelected === undefined) {
        isSelected = true
    }
    return (
        <div className = {styles.main}>
            <div className = {styles.maxSize}>
                <div className = {styles.content}>
                    <h1 className = {styles.title}>
                        {name}
                    </h1>
                    <div className = {styles.box + (isSelected ? " rounded-card" : "")}></div>
                </div> 
            </div>
        </div>
    )
}
