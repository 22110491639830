import React from 'react'
import styles from './LogoNameStack.module.css'
export default function LogoNameStack({id, name}) {
    return (
        <div className = {styles.main}>
            <div id = {id + '-card'}className = {styles.image}>
                <img style = {{opacity: 0, width: "100%", height: "100%"}} src = {require('../../images/icons/' + id + '.png')} alt = "Logo"></img>
            </div>
            <p style = {{opacity: 0}} className = {styles.name}>
                {
                    name.map(nameElement => {
                        if (nameElement.color === null) {
                            return <span key = {nameElement.value}>{nameElement.value}</span>
                        }
                        return <span key = {nameElement.value} style = {{color: nameElement.color}}>{nameElement.value}</span>
                    })
                }
            </p>
        </div>
    )
}
