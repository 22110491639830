import React from 'react'
import { Controller, Scene } from 'react-scrollmagic'
import SmallProjectCard from '../SmallProjectCard/SmallProjectCard'
import styles from './SmallProjectCardList.module.css'
export default function SmallProjectCardList({projects}) {

    return (
        <div className = {styles.main} >
            <Controller>
            {
                projects.map((project, index) => {
                    return ( <Scene key = {index}
                        triggerElement = {'#' + project.id}
                        offset={-20}
                        reverse = {false}
                        classToggle={['#' + project.id, 'rounded-card']}
                      >
                        <SmallProjectCard key = {index} {...project}></SmallProjectCard>
                    </Scene>
                    )
                })
            }
            {
                projects.map((project, index) => {
                    return ( <Scene key = {index}
                        triggerElement = {'#' + project.id}
                        offset={20}
                        reverse = {false}
                        classToggle={['#' + project.id + '-card', 'rounded-card']}
                        >
                            <></>
                    </Scene>
                    )
                })
            }
            </Controller>
        </div>
    )
}
