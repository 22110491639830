import React from 'react'
import styles from './ImageTextButton.module.css'
export default function ImageTextButton({name, imageName, link, color}) {
    return (
        <a href={link} target="_blank" rel="noopener noreferrer">
            <div className = {styles.main}>
                <img src = {require('./images/' + imageName + ".svg")} className = {styles.image} alt = "Button Logo"></img>
                <p style = {{color: color}}className = {styles.name}>{name}</p>
            </div>
        </a>
    )
}
