import React from 'react'
import LargeImageTextButton from '../LargeImageTextButton/LargeImageTextButton'
import styles from './Hero.module.css'
export default function Hero() {
    return (
        <div className = {styles.main}>
            <div className = {styles.apps}>
                <div className = {styles.logo + " rounded-card " + styles.socialDrop}>
                    <img className = {styles.logoImage} src = {require('../../images/icons/SocialDrop.png')} alt = "socialDrop Icon" ></img>
                </div>
                <div className = {styles.logo + " rounded-card "}>
                   <img className = {styles.logoImage} src = {require('../../images/icons/CloudBattery.png')} alt = "Cloud Battery Icon" ></img>
                </div>
                <div className = {styles.logo + " rounded-card " + styles.floatingfile}>
                    <img className = {styles.logoImage} src = {require('../../images/icons/FloatingFile.png')} alt = "floatingfile Icon" ></img>
                </div>
            </div>

            <div className = {styles.middleCircle}>
                <img src = {require('./images/header.png')} alt = "Header Icon" ></img>
                <p>ALAN <span className = {styles.lastName}>YAN</span></p>
            </div>

            <div className = {styles.links}>
                <LargeImageTextButton name = "Resume" imageName = "Resume" link = "./AlanYanResume.pdf"/>
                <LargeImageTextButton name = "Github" imageName = "Github" link = "https://github.com/yan-alan"/>
                <LargeImageTextButton name = "LinkedIn" imageName = "LinkedIn" link = "https://www.linkedin.com/in/yan-alan/"/>
                <LargeImageTextButton name = "Contact" imageName = "Contact" link = "mailto: alanyan@student.ubc.ca"/>
            </div>
        </div>
    )
}
