import React from 'react';

import { Controller, Scene } from 'react-scrollmagic'

import BigProjectCard from '../components/BigProjectCard/BigProjectCard';
import '../app.css'
import SmallProjectCardList from '../components/SmallProjectCardList/SmallProjectCardList';
import ExperienceItem from '../components/ExperienceItem/ExperienceItem';
import SectionTitle from '../components/SectionTitle/SectionTitle';
import Hero from '../components/Hero/Hero';
import DarkToggle from '../components/ThemeContext/DarkToggle';
import { bigProjects, smallProjects, experiences } from '../data/data'
import { ThemeContext } from '../components/ThemeContext/ThemeContext'
import SEO from '../components/SEO/seo'
function MainPage() {
  const { colorMode } = React.useContext(ThemeContext);
  return (
    <>
      <SEO title="Home" />
      <DarkToggle></DarkToggle>
      <Hero></Hero>
      <SectionTitle name="About Me" />
      <div id='About' className="App__AboutMeWrapper">
        <div className="App__AboutMe">
          <div className="App__AboutMe__Text">
            <p>Hi! My name is <span style={{ fontWeight: 700 }}>Alan Yan,</span> I'm a fourth year computer science student studying at UBC!
              I am a self-taught <span style={{ fontWeight: 700 }}>iOS developer,</span> who has worked at <span style={{ fontWeight: 700 }}>Robinhood</span> and <span style={{ fontWeight: 700 }}>Nanoleaf</span> as an iOS Intern
              and I have <span style={{ fontWeight: 700 }}>4</span> apps on the App Store with over <span style={{ fontWeight: 700 }}>200K</span> downloads!
              I am a <span style={{ fontWeight: 700 }}>WWDC 2021</span> Swift Student Challenge Winner and my app Cloud Battery has been featured as <span style={{ fontWeight: 700 }}>App Of The Day</span>.</p>
            <p>Outside of school, I am a food lover, a hockey player and love learning about tech and design!</p>

            <p>I am seeking full-time opportunities for <span style={{ fontWeight: 700 }}>May 2022.</span></p>
          </div>
          <object className="App_AboutMe__Image" type="image/svg+xml" data={require('../images/About-' + (colorMode === 'light' ? 'light' : 'dark') + '.svg')}>Your browser does
            not support SVGs</object>
        </div>
      </div>

      <SectionTitle name="Projects" />
      <div id="BigProjects" className="App__BigProjects_wrapper">
        <div className="App__BigProjects">
          <Controller>
            {bigProjects.map((project, index) => {
              return <Scene key={index}
                triggerElement={'#' + project.id}
                offset={-30}
                reverse={false}
                classToggle={['#' + project.id, 'rounded-card']}
              >
                <BigProjectCard key={index} {...project} />
              </Scene>
            })}
            {bigProjects.map((project, index) => {
              return <Scene key={index}
                offset={30}
                triggerElement={'#' + project.id}
                reverse={false}
                classToggle={['#' + project.id + '-card', 'rounded-card']}
              ><></></Scene>
            })}
          </Controller>
        </div>
      </div>
      <div className="App__SmallProjects">
        <SmallProjectCardList projects={smallProjects} />
      </div>
      <SectionTitle name="Experience" />

      <div className="App__Experiences">
        {experiences.map(experience => {
          return <ExperienceItem key={experience.id} {...experience} />
        })}
      </div>
    </>
  )
}

export default MainPage;
